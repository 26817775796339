













































/* eslint-disable */
import { Vue, Component, Emit, Model, Prop, Watch } from "vue-property-decorator";
import { api_group } from "@/api";
import { OtherModule } from "@/store/other";
import { UserModule } from "@/store/user";
import { Encryption } from "@/util/encryption";
@Component
export default class extends Vue{
    refresh = false
    user_list:any = [{uid:0}]
    timer:any = null
    need_out = true
    group_list:any = []

    async created(){
        await this.check_user()
        this.check_group_status()
        UserModule.get_info()
    }

    async beforeDestroy(){
        clearInterval(this.timer)
        if(this.need_out){
            await api_group.out_group({group_id:this.group_list[0]})
        }
    }


     check_user(){
        return new Promise(async(resolve:any)=>{
            this.refresh = true
            this.user_list = await api_group.check_user({group_id:this.group_id})
            this.$message.success('success')
            this.refresh = false
            resolve()
        })
    }

    async begin_group(){
        const res = await api_group.begin_group({group_id:this.group_id})
        this.need_out = false
        this.$router.replace(`/group/exam/${this.$route.params.group_id}/`)
    }

    check_group_status(){
        if(this.user_list[0].group_user_id !== this.user_id){
            this.timer = setInterval(async()=>{
                const res:any = await api_group.check_group_status({group_id:this.group_id})
                if(res.msg === 1){
                    this.need_out = false
                    this.$router.replace(`/group/exam/${this.$route.params.group_id}/`)
                }else if(res.msg === 2){
                    this.$message.error('방이 해체되었습니다.')
                    this.need_out = false
                    this.$router.go(-1)
                }
            },1000)
        }
    }

    async out_group(bool:boolean){
        if(bool){
            await this.$confirm('취소하기를 선택하시면, 본 그룹스터디는 해체됩니다. 정말 취소하시겠습니까?', '알림', {
                confirmButtonText: '확인',
                cancelButtonText: '취소',
            })
            this.delete_group()
        }else{
            await this.$confirm('취소하면 그룹스터디로 이동됩니다. 취소하시겠습니까?', '알림', {
                confirmButtonText: '확인',
                cancelButtonText: '취소',
            })
            const res = await api_group.out_group({group_id:this.group_id})
            this.need_out = false
            this.$router.go(-1)
        }
    }

    async delete_group(){
        await api_group.delete_group({group_id:this.group_id})
        this.need_out = false
        this.$router.go(-1)
    }

    get user_id (){
        return UserModule.info?.id
    }

    @Watch("group_id",{immediate:true})
    watch_group_id(){
        this.group_list.push(this.group_id)
    }

    get group_id(){
        return Number(Encryption.base_dec(this.$route.params.group_id || 'ODY='))
    }

  get is_mobile(): boolean {
    return OtherModule.is_mobile;
  }
}
